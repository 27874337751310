




















































































































































import {
  reactive,
  toRefs,
  defineComponent,
  onBeforeMount,
  watchEffect
} from '@vue/composition-api';
import { useUserActions } from '@/store';
import AButton from '@/components/atoms/AButton.vue';
import axios from 'axios';
import jsBase64 from 'js-base64';
import moment from 'moment';

const { getUserByKeyAndValue } = useUserActions(['getUserByKeyAndValue']);

interface IState {
  newPassword: string;
  reEnterPassword: string;
  msg: string;
  type: string;
  loading: boolean;
  show1: boolean;
  show2: boolean;
  userEmail: string | undefined;
  tokenExpirationDate: string | undefined;
  tokenVerified: boolean;
  isTokenUsed: boolean;
  pageLoading: boolean;
  isTokenInvalid: boolean;
  isPassSame: boolean;
  isEmailSent: boolean;
}

export default defineComponent({
  components: {
    AButton
  },
  props: {
    token: {
      type: String,
      default: ''
    }
  },

  setup(props, { root: { $router } }: any) {
    const state: IState = reactive({
      newPassword: '',
      reEnterPassword: '',
      msg: '',
      type: 'success',
      loading: false,
      show1: false,
      show2: false,
      userEmail: '',
      tokenExpirationDate: '',
      tokenVerified: false,
      isTokenUsed: false,
      pageLoading: true,
      isTokenInvalid: false,
      isPassSame: false,
      isEmailSent: false
    });

    watchEffect(() => {
      if (state.reEnterPassword === state.newPassword && !state.isPassSame) {
        state.isPassSame = true;
      } else if (state.reEnterPassword !== state.newPassword && state.isPassSame) {
        state.isPassSame = false;
      }
    });

    onBeforeMount(async () => {
      if (!props.token) {
        $router.push({ name: 'login' });
      } else {
        try {
          let decodedToken = { email: '', expirationTime: '' };
          try {
            decodedToken = JSON.parse(jsBase64.Base64.decode(props.token));
          } catch (err) {
            state.isTokenInvalid = true;
            throw err;
          }
          state.userEmail = decodedToken?.email;
          state.tokenExpirationDate = decodedToken?.expirationTime;
          const userInfo = await getUserByKeyAndValue({
            key: 'email',
            value: decodeURIComponent(decodedToken?.email)
          });
          if (userInfo && userInfo?.data) {
            state.isTokenInvalid = false;
            if (userInfo.data?.token && userInfo.data?.token === props.token) {
              if (moment().isAfter(decodedToken?.expirationTime)) {
                state.tokenVerified = false;
              } else {
                state.tokenVerified = true;
              }
            } else {
              state.isTokenUsed = true;
            }
          } else {
            state.isTokenInvalid = true;
            state.tokenVerified = false;
          }
        } catch (err) {
          state.tokenVerified = false;
        } finally {
          state.pageLoading = false;
        }
      }
    });

    const stylings = {
      newPassword: {
        class: '',
        outlined: true,
        rounded: true,
        xLarge: true,
        dark: true,
        depressed: true,
        fullWidth: true,
        label: 'Enter current password',
        placeholder: 'Password',
        toggle: true,
        color: 'white',
        appendIcon: state.show1 ? 'mdi-eye' : 'mdi-eye-off'
      },
      reEnterPassword: {
        class: 'login__input',
        outlined: true,
        rounded: true,
        xLarge: true,
        depressed: true,
        fullWidth: true,
        label: 'Re-enter new password',
        placeholder: 'Password',
        toggle: true,
        color: 'white',
        appendIcon: state.show2 ? 'mdi-eye' : 'mdi-eye-off'
      }
    };

    const clearMessage = () => {
      setTimeout(() => {
        state.msg = '';
      }, 4000);
    };

    const resendEmail = async () => {
      state.loading = true;
      try {
        const data = {
          token: props.token,
          email: state.userEmail
        };
        const resp = await axios.post(
          `${process.env.VUE_APP_AUTH_STAGE_SERVICE}/resend-link`,
          data,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        if (resp.status === 200) {
          state.isEmailSent = true;
          state.msg = 'Reset password email sent successfully';
          state.type = 'success';
          clearMessage();
        }
      } catch (error) {
        state.msg = 'Something went wrong!';
        state.type = 'error';
        clearMessage();
      } finally {
        state.loading = false;
      }
    };

    async function forcedPass() {
      state.loading = true;
      try {
        const data = {
          token: props.token,
          email: state.userEmail,
          password: state.newPassword,
          confirmPassword: state.reEnterPassword
        };
        const resp = await axios.post(
          `${process.env.VUE_APP_AUTH_STAGE_SERVICE}/set-new-password`,
          data,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        if (resp.status === 200) {
          $router.push({ name: 'login' });
        }
      } catch (error) {
        console.log(error);
      } finally {
        state.loading = false;
      }
    }

    function togglePasswordFirst() {
      state.show1 = !state.show1;
    }
    function togglePasswordSecond() {
      state.show2 = !state.show2;
    }

    return {
      ...toRefs(state),
      forcedPass,
      togglePasswordFirst,
      togglePasswordSecond,
      stylings,
      resendEmail
    };
  }
});
